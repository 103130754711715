import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useTranslation } from '../views/Translate';

export default function ChatPage() {
  useEffect(() => {
    window.addEventListener('chatbot-loaded', function () {
      window.dispatchEvent(new Event('ew-open-window'));
    });
  });

  return (
    <>
      <Helmet>
        <script async defer>
          {`
          var ewConfig = {
            "tokenStorage": "LOCAL_STORAGE",
            "customButton": true,
            "openStateStorage": "LOCAL_STORAGE",
            "openedAtFirstVisit": true,
            "openedAtFirstVisitMobile": true,
            "hashForceOpen": true,
            "hashForOpenAtFirstVisit": "chatbot-open",
            "theme": "chatbot-artin",
            "instanceId": 2,
            "url": "https://hoppygo.bot.coworkers.ai",
            "urlPrefix": "https://hoppygo.bot.coworkers.ai"
          };
          (function (w, d, c) {
            var p = ['vendor.js', 'app.js'];
            for (var i = 0; i < p.length; i++) {
              var s = d.createElement('script');
              s.src = c.url + '/ew/' + p[i];
              d.getElementsByTagName('head')[0].appendChild(s);
            }
          })(window, document, ewConfig);
        `}
        </script>
      </Helmet>
      {/*<div style={{ width: 500, height: 500 }}>test</div>*/}
    </>
  );
}
